$(function(){
  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('ul').removeClass('open');
      $('.overlay').removeClass('open');
    } else {
      $(this).addClass('active');
      $(this).next('ul').addClass('open');
      $('.overlay').addClass('open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('.nav-sp ul').removeClass('open');
    }
  });
  if ($('body').hasClass('slick')) {
    $('.slider').slick({
      arrows: false,
      autoplay:true,
      autoplaySpeed:5000,
      dots:true,
      pauseOnHover: false,
      speed: 1005
  	});
  }
  if ($('body').hasClass('home')) {
    $('.slider').slick({
      arrows: false,
      autoplay:true,
      autoplaySpeed:5000,
      dots:false,
      pauseOnHover: false,
      speed: 2000,
      slidesToShow:1,
      centerMode: true,
      centerPadding:'0%',
      slidesToScroll:1,
      responsive: [{
        breakpoint: 684,
        settings: {
          centerPadding:'0%'
        }
      }]
  	});
  }
  if ($('body').hasClass('works-detail')) {
    $('.readmore').on('click',function(){
      $('.more').slideDown();
      $(this).hide();
    });
  }

  // スマホ以外電話リンクOFF
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
  if (!isMobile) {
      $('a[href^="tel:"]').on('click', function(e) {
          e.preventDefault();
      }).addClass('disable');
  }
  $('#menu .menu, a.btn, #navbar a').on('touchstart', function(){
   $(this).addClass('hover');
  }).on('touchend', function(){
   $(this).removeClass('hover');
  });

  if ($('body').hasClass('blog-detail')) {
    $('.entry-body img').removeAttr('width height');
  }

});

//toTopの表示・非表示
function displayToTop(){
  var scrollTop = $(window).scrollTop();
  if (scrollTop < 300) {
    $(".to-page-top").fadeOut();
  } else {
    $(".to-page-top").fadeIn();
    $(".to-page-top a").smoothScroll({offset: -80});
  }
}
$(window).on("load scroll", displayToTop);

$(window).on("load resize", function(){
  var windowHeight = $(window).height();
  var bodyHeight = $('body').height();
  if (windowHeight>bodyHeight+60) {
    $('footer').addClass('fixed-bottom');
  } else {
    $('footer').removeClass('fixed-bottom');
  }
  if ($('body').hasClass('works-detail')) {
    var photosMaxHeight = $('#photos').width();
    $('#photos img').css({
      'maxHeight':photosMaxHeight+'px',
      'display':'auto'
    });
    $('#photos img').removeAttr('width height');

  }
});

$(function() {
    $(".effect div").css("opacity", "0"), $(window).scroll(function() {
        $(".effect").each(function() {
            var o = $(this).offset().top,
                n = $(window).scrollTop(),
                t = $(window).height();
            o - t + t / 10 < n ? $("div", this).css("opacity", "1") : $("div", this).css("opacity", "0")
        })
    })
});
